import { Priority, ServiceActivity } from '@/generated'
import moment from 'moment'
import { EXCEL_CSV_TIMESTAMP_FORMAT } from '@/components/export/ExportCsvButton.tsx'
import { BadgeColor } from '@/components/core/badges/BadgeColor.ts'
import { toExcelCsvDateFormat } from './MomentHelpers'

export const isTypeOfTicket = (serviceActivity: ServiceActivity) => {
  return serviceActivity.workOrdersCount === null
}

export const formatCSVRow = (
  serviceActivity: ServiceActivity
): ServiceActivity => {
  return {
    ...serviceActivity,
    closedAt:
      serviceActivity.closedAt &&
      moment(serviceActivity.closedAt).format(EXCEL_CSV_TIMESTAMP_FORMAT),
    createdAt: moment(serviceActivity.createdAt).format(
      EXCEL_CSV_TIMESTAMP_FORMAT
    ),
    updatedAt: moment(serviceActivity.updatedAt).format(
      EXCEL_CSV_TIMESTAMP_FORMAT
    ),
    scheduleDate:
      serviceActivity.scheduleDate &&
      toExcelCsvDateFormat(serviceActivity.scheduleDate),
  }
}

export const priorityToBadgeColor = (
  priority: Priority | null
): keyof typeof BadgeColor => {
  if (priority === Priority.Urgent) {
    return 'red'
  } else if (priority === Priority.High) {
    return 'yellow'
  }

  return 'gray'
}

export const statusToBadgeColor = (
  status: string,
  pendingClientAction: boolean = false
): keyof typeof BadgeColor => {
  if (pendingClientAction) {
    return 'red'
  }
  if (closedStatuses.has(status)) {
    return 'green'
  }
  return 'blue'
}

export const calcOpenDays = (createdAt: string, closedAt?: string): number => {
  return moment(closedAt).diff(moment(createdAt), 'days')
}

const statusSortMap = new Map<string, number>([
  ['New', 0],
  ['Open', 1],
  ['Pending', 2],
  ['Hold', 3],
  ['On Hold', 4],
  ['Solved', 5],
  ['Completed', 6],
  ['Closed', 7],
])
const closedStatuses = new Set(['Solved', 'Completed', 'Closed'])

export const isServiceActivityClosed = (serviceActivity: ServiceActivity) => {
  return closedStatuses.has(serviceActivity.status)
}

export function serviceActivityStatusSort(statusA: string, statusB: string) {
  const p1 = statusSortMap.get(statusA) ?? 1_000_000
  const p2 = statusSortMap.get(statusB) ?? 1_000_000
  if (p1 < p2) return -1
  if (p1 > p2) return 1
  return 0
}

import {
  FilterDrawerFacetFilterDef,
  FilterDrawerFilterDef,
} from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { NestedKeyOf } from '@/helpers/TypeHelpers.ts'
import {
  lastNDaysPreset,
  nextDayPreset,
  nextNDaysPreset,
} from '@/components/core/datepickers/presets.ts'
import { ticketPrioritySort } from '@/components/table/sort/ticketPrioritySort.ts'

import { serviceActivityStatusSort } from '@/helpers/ServiceActivityHelper.ts'
import { ServiceActivity } from '@/generated'
import { PendingActionText } from '@/pages/serviceActivity/commercial/types.ts'

export function creationDateFilter<T>(
  key: NestedKeyOf<T>
): FilterDrawerFilterDef<T> {
  return {
    type: 'date-range',
    key: key,
    header: 'Creation Date',
    presets: [
      lastNDaysPreset(30, { includeEndDate: true }),
      lastNDaysPreset(60, { includeEndDate: true }),
      lastNDaysPreset(90, { includeEndDate: true }),
    ],
  }
}

export function updatedDateFilter<T>(
  key: NestedKeyOf<T>
): FilterDrawerFilterDef<T> {
  return {
    type: 'date-range',
    key: key,
    header: 'Last Updated',
    presets: [
      lastNDaysPreset(30, { includeEndDate: true }),
      lastNDaysPreset(60, { includeEndDate: true }),
      lastNDaysPreset(90, { includeEndDate: true }),
    ],
  }
}

export function scheduledDateFilter<T>(
  key: NestedKeyOf<T>
): FilterDrawerFilterDef<T> {
  return {
    type: 'date-range',
    key: key,
    header: 'Upcoming Site Visits (Schedule Date)',
    presets: [
      nextDayPreset(),
      nextNDaysPreset(7, { includeEndDate: true }),
      nextNDaysPreset(30, { includeEndDate: true }),
      nextNDaysPreset(60, { includeEndDate: true }),
    ],
  }
}

export function closedDateFilter<T>(
  key: NestedKeyOf<T>
): FilterDrawerFilterDef<T> {
  return {
    type: 'date-range',
    key: key,
    header: 'Closed Date',
    presets: [
      lastNDaysPreset(30, { includeEndDate: true }),
      lastNDaysPreset(60, { includeEndDate: true }),
      lastNDaysPreset(90, { includeEndDate: true }),
    ],
  }
}

export function assetManagerFilter<T>(
  key: NestedKeyOf<T>,
  rowDataAccessor: (rowData: T) => string | null
): FilterDrawerFilterDef<T> {
  return {
    type: 'faceted',
    key: key,
    header: 'Asset Manager',
    rowDataAccessor: rowDataAccessor,
  }
}

export function creationReasonFilter<T>(
  key: NestedKeyOf<T>,
  rowDataAccessor: (rowData: T) => string | null
): FilterDrawerFilterDef<T> {
  return {
    type: 'faceted',
    key: key,
    header: 'Ticket Creation Reason',
    rowDataAccessor: rowDataAccessor,
  }
}

export function statusFilter<T>(
  key: NestedKeyOf<T>,
  rowDataAccessor: (rowData: T) => string | null,
  serviceCloudIntegrationEnabled = false
): FilterDrawerFilterDef<T> {
  return {
    type: 'faceted',
    key: key,
    header: `${serviceCloudIntegrationEnabled ? 'Case' : 'Ticket'} Status`,
    rowDataAccessor: rowDataAccessor,
    sortingFn: serviceActivityStatusSort,
  }
}

export function subStatusFilter<T>(
  key: NestedKeyOf<T>,
  rowDataAccessor: (rowData: T) => string | null
): FilterDrawerFilterDef<T> {
  return {
    type: 'faceted',
    key: key,
    header: 'Ticket Progress',
    rowDataAccessor: rowDataAccessor,
  }
}

export function priorityFilter<T>(
  key: NestedKeyOf<T>,
  rowDataAccessor: (rowData: T) => string | null
): FilterDrawerFacetFilterDef<T> {
  return {
    type: 'faceted',
    key: key,
    header: 'Priority',
    rowDataAccessor: rowDataAccessor,
    sortingFn: ticketPrioritySort,
  }
}

export function serviceActivityPendingClientActionFilterDataAccessor(
  rowData: ServiceActivity
) {
  return rowData.pendingClientAction
    ? PendingActionText.yes
    : PendingActionText.no
}
export function pendingClientActionFilter<T>(
  key: NestedKeyOf<T>,
  rowDataAccessor: (rowData: T) => string | null
): FilterDrawerFacetFilterDef<T> {
  return {
    type: 'faceted',
    key,
    header: 'Pending Client Action',
    rowDataAccessor,
  }
}

import { CellContext } from '@tanstack/react-table'
import { ServiceActivity } from '@/generated'

export default function PendingClientActionCell(
  ctx: Readonly<CellContext<ServiceActivity, unknown>>
) {
  const { pendingClientAction } = ctx.row.original
  if (pendingClientAction) {
    return (
      <div className="tw-flex tw-items-center tw-gap-1">
        <div
          data-testid="pending-client-action-flag"
          className="tw-h-3 tw-w-3 tw-rounded-full tw-bg-red-500"
        />
        Needs Attention
      </div>
    )
  }
  return null
}
